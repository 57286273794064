import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <p>© 2021 NAGAO WRECKER INC. ALL RIGHTS RESERVED</p>
      </footer>
    );
  }
}

export default Footer;
